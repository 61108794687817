<template>
    <div>
      <header id="header" class="header">
      <div class="row">
        <div class="col-md-3">
          <div class="logo">Auditee</div>
        </div>
        <div class="col-md-5">
          <!-- <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text"><i class="fas fa-search"></i></div>
            </div>
            <input type="text" class="form-control" id="inputSearch" placeholder="Pesquisar">
          </div> -->
        </div>
        <div class="col-md-4">
          <!-- <img class="profile-img" :src="$store.getters.user.photo" alt="Profile"> -->
          <div class="dropdown">
            <button class="btn btn-profile dropdown-toggle" type="button" id="dropdownProfile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{ $store.getters.user.name }}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownProfile">
              <!-- <a class="dropdown-item" href="#"><i class="far fa-user"></i>Perfil</a> -->
              <a class="dropdown-item clickable" @click="logout()"><i class="fas fa-sign-out-alt"></i>Sair</a>
            </div>
          </div>
          <div class="dropdown">
            <div class="dropdown-menu">
              <a class="dropdown-item new" href="#"><i class="far fa-envelope"></i>Nova solicitação<span>Há 3 minutos</span></a>
              <a class="dropdown-item" href="#"><i class="far fa-envelope-open"></i>Alteração de Horário<span>10:48</span></a>
              <a class="dropdown-item" href="#"><i class="far fa-envelope-open"></i>Serviço Finalizado<span>Ontem</span></a>
              <a class="dropdown-item" href="#"><i class="far fa-envelope-open"></i>Avaliação Pendente<span>20/10/20</span></a>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div id="content">
      <div class="menu">
        <div class="accordion" id="accordionMenu">
          <nav class="nav nav-fill" id="menu">
            <router-link :to="'/'" class="nav-item nav-link active"><i class="fas fa-home"></i>Home</router-link>
            <router-link :to="'/tests'" class="nav-item nav-link"><i class="fas fa-file-invoice"></i>Testes</router-link>
            <router-link :to="'/batch'" class="nav-item nav-link"><i class="fas fa-cog"></i>Progresso de Lotes</router-link>
            <a href="#collapseMenu01" class="nav-item nav-link" data-toggle="collapse">
              <i class="fas fa-database"></i>Dados
            </a>
          </nav>
          <nav class="nav nav-fill collapse" id="collapseMenu01" data-parent="#accordionMenu">
            <router-link :to="'/products'" href="test01.html" class="nav-item nav-link"><i class="fas fa-clipboard-list"></i>Produtos</router-link>
            <router-link :to="'/suppliers'" href="#" class="nav-item nav-link"><i class="fas fa-clipboard-list"></i>Fornecedores</router-link>
            <router-link :to="'/purchase-order'" href="suppliers.html" class="nav-item nav-link"><i class="fas fa-users"></i>Pedidos de Compra</router-link>
            <router-link :to="'/invoices'" href="suppliers.html" class="nav-item nav-link"><i class="fas fa-users"></i>Notas Fiscais</router-link>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    logout: function() {
      let self = this;
      let api = this.$store.state.api + "logout";

      self.$http
        .post(api)
        .then(response => {
          localStorage.removeItem("token");

          self.$router.push({ path: "/login" });
        })
        .catch(error => {
          this.errors = error.response.data.errors;
          this.$message("Erro", "Erro ao realizar logout", "error");
        });
    }
  }
};
</script>

<style>
</style>
