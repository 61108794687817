<template>
    <div>
        <div class='main-content'>
            <header-navbar></header-navbar>
            <router-view></router-view>
        </div>
        <Footer></Footer>
    </div>
    
</template>

<script>
    import HeaderNavbar from './HeaderNavbar'
    import LeftNavbar from './LeftNavbar'
    import Breadcrumb from './Breadcrumb'
    import Footer from './Footer'
    export default {
        name: "app",
        data: () => ({
            drawer: null,
            authenticated: false
        }),
        props: {
            source: String
        },
        mounted () {
            this.authenticated = this.$store.getters.authenticated

            $('body').removeClass('top-navigation')
            $('body').removeClass('custom-login')
        },
        components: {
            HeaderNavbar, LeftNavbar, Breadcrumb, Footer
        }
    }
</script>

<style>
    body {
        padding: 0px !important;
    }
    #wrapper{
        margin-top: 82px;

    }
</style>

